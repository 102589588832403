import React from 'react';
import 'pages/intro/Intro.scss'
import EntireFlow from 'assets/images/icons/medical-portal.png';
import medicalLogo from 'assets/images/icons/first-aid-kit_2_30.png';
import {routes} from 'constants/routes';
import {Container, Nav, Navbar, NavbarBrand} from 'react-bootstrap'

/**
 * Stateless component responsible for rendering a simple SSI introduction screen.
 * */
const IntroPage = () => {
  return (
    <div className='intro page-form page-form--large'>
      <div className='intro__heading-block'>
      <img src={medicalLogo} className='logo-pharmacy' alt='logo-pharm'/>
      <br/>
      <br/>
        <h1 className='intro__heading'>
          Medical Portal
        </h1>
        <h5 className='intro__subheading'>Patient Health Data Distributed Interface</h5>
      </div>
      <div className='intro__text-block'>
        <h4>Scenario</h4>
        <p>The patient is feeling unwell and quickly travels to a medical clinic to see a doctor.</p>
        <h4>Roles in this scenario</h4>
        <p>There are 3 roles in SSI cycle: <strong>ISSUER</strong>, <strong>ENDORSER</strong>, and <strong>VERIFIER</strong>. Each of them is explained in the example below.</p>
      </div>
      <div className='intro__example'>
        <img className='flow-size' src={EntireFlow} alt='entire-flow'/>
      </div>
      <div className='intro__roles-description'>
        <div className='intro__roles-description-role'>
          <h3>Issuer</h3>
          <p>Doctor issues a digital verified credential for the medical consultation and enters details about the investigation and prognosis.</p>
        </div>
        <div className='intro__roles-description-role'>
          <h3>Endorser</h3>
          <p>Doctor explains to the patient about the assessments and prognosis.  The patient endorses the digital verifiable credential to attest that the doctor has undertaken the stated assessments.</p>
        </div>
        <div className='intro__roles-description-role'>
          <h3>Verifier</h3>
          <p>Patient shares the endorsed digital verifiable credential with the Insurer.  The insurer is satisfied that the assessments by the doctor have taken place and initiates reimbursement payment to the doctor for the stated assessments, consumables, and other associated charges.</p>
        </div>
      </div>

      <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

    </div>
  )
}

export default IntroPage
